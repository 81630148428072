import React from "react"
import { Typography,Breadcrumb } from 'antd';
import MainLayout from "../components/layout/MainLayout";
import SEO from "../components/seo";

const { Title } = Typography;

export default () => {

    let breadcrumbItems = [(<Breadcrumb.Item key="support-us">Support us</Breadcrumb.Item>)]
    return (
        <MainLayout breadcrumbItems={breadcrumbItems}> 
            <SEO title={`Support us!`} description={`Football Manager most precise tactic test results by MrLTactics.com.`} />
            <Title>Support us via DonorBox!</Title>
            <script src="https://donorbox.org/widget.js" paypalExpress="true"></script>
            <iframe title="donorbox" allowpaymentrequest="" frameborder="0" height="900px" name="donorbox" scrolling="no" seamless="seamless" src="https://donorbox.org/embed/mrltactics?default_interval=o&amount=5&hide_donation_meter=true" style={{maxWidth: 500, minWidth:310, maxHeight:"none!important"}} width="100%"></iframe>
        </MainLayout>
    )
};
